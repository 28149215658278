import React from "react";
import styled from "styled-components";
import { graphql, Link, useStaticQuery } from "gatsby";
import breakpoints from "../utils/breakpoints";

import hero from "../assets/images/hero.jpg";
import logo from "../assets/images/logo-icon.png";
import defino from "../assets/images/zertifikat-defino.png";
import whofinance from "../assets/images/zertifikat-whofinance_2021.png";

const HeroStyles = styled.section`
  position: relative;
  width: 100%;
`;

const HeroImage = styled.div`
  width: 100%;
  height: 75vh;
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
  background: url(${hero}) no-repeat center/cover;

  @media (orientation: portrait) {
    height: 46.875vw;
  }
`;

const Certificate = styled.img`
  position: absolute;
  right: var(--spacing-xs);
  bottom: var(--spacing-xs);
  max-width: 12rem;
  transform: rotate(2deg);
  z-index: 1;

  ${breakpoints.small`
    right: var(--spacing-s);
    bottom: var(--spacing-xs);
    max-width: 19rem;
  `};

  ${breakpoints.medium`
    right: var(--spacing);
    bottom: var(--spacing-s);
  `};

  ${breakpoints.large`
    right: var(--spacing-l);
    bottom: var(--spacing);
    max-width: 26rem;
    `};

  ${breakpoints.xlarge`
    bottom: var(--spacing-l);
    max-width: 36rem;
  `};
`;

// const Callout = styled.div`
//   position: absolute;
//   right: var(--spacing-xs);
//   bottom: var(--spacing-xs);
//   max-width: 14rem;
//   padding: 0.5rem 1rem;
//   color: var(--color-white);
//   background-color: var(--color-accent);
//   transform: rotate(2deg);
//   z-index: 1;

//   ${breakpoints.small`
//     right: var(--spacing-s);
//     bottom: var(--spacing-xs);
//     padding: 0.75rem 1.5rem;
//     max-width: 19rem;
//   `};

//   ${breakpoints.medium`
//     right: var(--spacing);
//     bottom: var(--spacing-s);
//   `};

//   ${breakpoints.large`
//     right: var(--spacing-l);
//     bottom: var(--spacing);
//     padding: 1rem 2rem;
//     max-width: 35rem;
//   `};

//   ${breakpoints.xlarge`
//     bottom: var(--spacing-l);
//   `};

//   h1 {
//     display: flex;
//     align-items: center;
//     font-size: var(--font-size);
//     /* letter-spacing: 1px; */
//     margin-bottom: var(--spacing-xs);

//     ${breakpoints.small`
//       font-size: var(--font-size-l);
//       // letter-spacing: 2px;
//       `};

//     ${breakpoints.large`
//       font-size: var(--font-size-xxl);
//       margin-bottom: var(--spacing-s);
//     `};

//     img {
//       width: 16px;
//       margin-right: var(--spacing-s);

//       ${breakpoints.small`
//         width: var(--spacing);
//       `};

//       ${breakpoints.large`
//         width: var(--spacing-l);
//         margin-right: var(--spacing);
//       `};
//     }
//   }

//   p {
//     font-size: var(--font-size-s);
//     font-weight: var(--font-weight-light);
//     line-height: var(--line-height-s);
//     margin-bottom: var(--spacing-s);

//     ${breakpoints.small`
//       font-size: var(--font-size);
//       // letter-spacing: 2px;
//       `};

//     ${breakpoints.large`
//       font-size: var(--font-size-xl);
//       margin-bottom: var(--spacing);
//     `};
//   }
// `;

// const ZertifikateGrid = styled.div`
//   display: grid;
//   grid-template-columns: 3fr 4fr;
//   gap: var(--spacing);

//   ${breakpoints.medium`
//     gap: var(--spacing-l);
//   `};

//   > img {
//     width: 100%;
//   }
// `;

export default function Hero() {
  const {
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          description
          author {
            name
          }
        }
      }
    }
  `);

  const {
    description,
    author: { name },
  } = siteMetadata;

  return (
    <HeroStyles>
      <HeroImage />

      {/* <Certificate src={whofinance} alt="WhoFinance zertifiziert" /> */}

      {/* <Callout>
        <h1>
          <img src={logo} alt="FORMAXX AG" /> {name}
        </h1>
        <p>{description}</p>
        <ZertifikateGrid>
          <img src={whofinance} alt="WhoFinance zertifiziert" />
          <img src={defino} alt="Defino zertifiziert" />
        </ZertifikateGrid>
      </Callout> */}
    </HeroStyles>
  );
}
