import styled from "styled-components";

const SocialLinks = styled.div`
  ul {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  a {
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);
    padding: var(--spacing-xs) var(--spacing-s);
    transition: transform var(--duration-s);
  }

  svg {
    font-size: var(--font-size-l);
    color: var(--color-primary);
  }
`;

export const SocialLinksHorizontal = styled(SocialLinks)`
  display: flex;
  align-items: center;

  p {
    margin-bottom: 0;
  }
`;

export const SocialLinksVertical = styled(SocialLinks)`
  p {
    margin-bottom: var(--spacing-xs);
  }
`;

export default SocialLinks;
