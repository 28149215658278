import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import styled from "styled-components";
import {
  FaArrowRight,
  FaCheck,
  FaXing,
  FaGoogle,
  FaFacebookF,
  FaExclamation,
} from "react-icons/fa";
import SEO from "../components/SEO";
import Hero from "../components/Hero";
import Section, { ColoredSection, SectionContent } from "../components/Section";
import Blockquote from "../components/Blockquote";
import { SocialLinksVertical } from "../components/SocialLinks";
import breakpoints from "../utils/breakpoints";

import burki from "../assets/images/andreas-burkhardt.jpg";
import defino from "../assets/images/zertifikat-defino.png";
import faz from "../assets/images/zertifikat-faz.png";
import whofinance2021 from "../assets/images/zertifikat-whofinance_2021.png";
import whofinance2022 from "../assets/images/zertifikat-whofinance_2022.png";
import whofinance2023 from "../assets/images/zertifikat-whofinance_2023.png";
import whofinance2024 from "../assets/images/zertifikat-whofinance_2021.png";
import contact from "../assets/images/contact.jpg";
import logo from "../assets/images/logo-alt.png";

import IconIndividuell from "../assets/svg/beratung-individuell.svg";
import IconLangfristig from "../assets/svg/beratung-langfristig.svg";
import IconUnabhaengig from "../assets/svg/beratung-unabhaengig.svg";
import IconVerstaendlich from "../assets/svg/beratung-verstaendlich.svg";

import IconGeldanlage from "../assets/svg/leistungen-geldanlage.svg";
import IconAltersvorsorge from "../assets/svg/leistungen-altersvorsorge.svg";
import IconBaufinanzierung from "../assets/svg/leistungen-baufinanzierung.svg";
import IconImmobilienvermittlung from "../assets/svg/leistungen-immobilienvermittlung.svg";
import IconVersicherungen from "../assets/svg/leistungen-versicherungen.svg";

const BeratungSection = styled(Section)`
  text-align: center;

  > div {
    padding-top: var(--spacing-xl);

    ${breakpoints.large`
      padding-top: var(--spacing-xxxl);
    `};
  }
`;

const BeratungGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-l);
  margin-bottom: var(--spacing-l);

  ${breakpoints.large`
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `};

  > div {
    svg {
      color: var(--color-primary);
      font-size: var(--font-size-xxxl);
      margin-bottom: var(--spacing-s);
      width: 1.75em;
      height: 1.75em;
    }

    h4 {
      color: var(--color-white);
      margin-bottom: var(--spacing-s);
    }

    &:nth-child(2) svg {
      transform: scaleX(-1);
    }
  }
`;

const PhilosophieSection = styled(ColoredSection)`
  /* background-color: var(--color-primary); */
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-primary);
    transform: skewY(-5deg);
    transform-origin: 50%;
  }

  > div {
    padding-top: var(--spacing-xl);
  }
`;

const PhilosophieHeader = styled.div`
  display: flex;
  gap: var(--spacing);
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: var(--spacing);

  ${breakpoints.medium`
    gap: var(--spacing-l);
  `};

  h3 {
    color: var(--color-accent);
    margin-bottom: 0;
  }

  > img {
    width: 100%;
    max-width: 10rem;
    transform: rotate(2deg);

    ${breakpoints.small`
      max-width: 19rem;
      margin-bottom: var(--spacing);
    `};

    ${breakpoints.large`
      max-width: 25rem;
    `};
  }
`;

const PhilosophieContent = styled.div`
  margin-bottom: var(--spacing-l);

  ul {
    list-style: none;
  }

  li {
    display: flex;
    align-items: center;
    font-style: italic;
    margin-bottom: var(--spacing-xs);

    svg {
      flex: 0 0 auto;
      color: var(--color-accent);
      margin-right: var(--spacing-s);
    }
  }
`;

const LeistungenSection = styled(Section)`
  text-align: center;
`;

const LeistungenGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-l);
  align-items: flex-end;
  margin-bottom: var(--spacing-l);

  ${breakpoints.medium`
    grid-template-columns: 4fr 5fr;
  `};

  ${breakpoints.xlarge`
    grid-template-columns: 4fr 5fr 4fr;
  `};

  > div {
    text-align: left;

    &:last-child {
      text-align: center;

      ${breakpoints.medium`
        text-align: left;
      `};
    }

    ul {
      list-style: none;
      margin-bottom: 0;
    }

    li {
      display: flex;
      align-items: center;

      svg {
        margin-right: var(--spacing-s);
      }
    }
  }

  .button {
    padding: 0;
  }
`;

const LeistungenHeadline = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: var(--spacing-s);

  svg {
    color: var(--color-primary);
    font-size: var(--font-size-xxl);
    width: 1em;
    height: 1em;
    margin-right: var(--spacing-s);
  }

  h4 {
    text-transform: uppercase;
    margin-bottom: 0;
  }
`;

const AboutSection = styled(Section)`
  h2 {
    text-align: center;
  }
`;

const ContactSection = styled(ColoredSection)`
  position: relative;
  color: var(--color-white);
  background: url(${contact}) no-repeat center bottom/cover;
  clip-path: polygon(0 20%, 100% 0, 100% 100%, 0% 100%);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    --overlay-color: 51, 51, 51;
    background: linear-gradient(
      to right,
      rgba(var(--overlay-color), 1),
      rgba(var(--overlay-color), 0)
    );
  }

  > div {
    padding-top: var(--spacing-xxxxl);
  }

  h2 {
    color: var(--color-white);
  }

  a {
    color: var(--color-white);
  }
`;

const AboutGrid = styled.div`
  margin-bottom: var(--spacing-xl);

  ${breakpoints.small`
    display: grid;
    grid-template-columns: 1fr 2fr;
  `};

  ${breakpoints.xlarge`
    display: grid;
    grid-template-columns: 1fr 3fr;
  `};

  > div {
    ${breakpoints.small`
      margin-left: var(--spacing);
    `};

    ${breakpoints.large`
      margin-left: var(--spacing-l);
    `};
  }

  img {
    margin-bottom: var(--spacing);

    ${breakpoints.small`
      margin-bottom: 0;
    `};
  }
`;

const ZertifikateSection = styled(Section)`
  text-align: center;
`;

const ZertifikateGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
  margin-bottom: var(--spacing-l);

  .row {
    display: flex;
    flex-direction: column;
    gap: var(--spacing);

    ${breakpoints.small`
      flex-direction: row;
      justify-content: center;
    `};

    ${breakpoints.medium`
      gap: var(--spacing-l);
    `};

    > img {
      align-self: center;
      max-width: 65%;

      ${breakpoints.small`
        max-width: none;
      `};
    }

    &:first-child {
      > img {
        ${breakpoints.small`
          height: var(--spacing-xl);
        `};

        ${breakpoints.medium`
          height: var(--spacing-xxxl);
        `};

        ${breakpoints.large`
          height: var(--spacing-xxxxl);
        `};

        &:last-child {
          ${breakpoints.small`
            order: -1;
          `};
        }
      }
    }

    &:last-child {
      ${breakpoints.xlarge`
        gap: var(--spacing-xl);
      `};

      > img {
        ${breakpoints.small`
          height: var(--spacing-l);
        `};

        ${breakpoints.medium`
          height: var(--spacing-xxl);
        `};

        ${breakpoints.large`
          height: var(--spacing-xxxl);
        `};
      }
    }
  }

  /* display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-l);

  ${breakpoints.medium`
    grid-template-columns: 3fr 4fr 1fr;
  `};

  > img {
    width: 100%;
  } */
`;

const CertificateButtons = styled.div`
  display: inline-flex;
  justify-content: center;
  gap: var(--spacing-xs);
  flex-direction: column;

  ${breakpoints.medium`
  flex-direction: row;
  `};

  ${breakpoints.large`
  gap: var(--spacing-l);
  `};

  a {
    display: flex;
    gap: var(--spacing-xs);
    justify-content: center;

    ${breakpoints.small`
    font-size: var(--font-size-l);
    `};

    svg {
      margin: 0;
    }
  }
`;

const ContactGrid = styled.div`
  display: grid;
  gap: var(--spacing-l);

  ${breakpoints.small`
    grid-template-columns: 1fr 1fr;
  `};

  ${breakpoints.large`
    grid-template-columns: 2fr 3fr;
  `};

  h3 {
    margin-bottom: var(--spacing-s);
  }

  a {
    text-decoration: underline;
  }

  ${SocialLinksVertical} a {
    &:hover {
      transform: scale(1.2);
    }
  }
`;

const ContactStandort = styled.div`
  margin-bottom: var(--spacing);

  &:last-child {
    margin-bottom: 0;
  }

  h4 {
    margin-bottom: var(--spacing-s);
  }
`;

export default function HomePage() {
  const {
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          description
          author {
            name
            email
            phone
            phonelink
            address {
              street
              zip
              city
            }
          }
        }
      }
    }
  `);

  const {
    description,
    author: {
      name,
      phone,
      phonelink,
      email,
      address: { street, zip, city },
    },
  } = siteMetadata;

  return (
    <>
      <SEO />

      <Hero />

      <ZertifikateSection>
        <SectionContent>
          <h1>{name}</h1>
          <h2>{description}</h2>

          <ZertifikateGrid>
            <div className="row">
              <img src={defino} alt="Defino zertifiziert" />
              <img src={whofinance2023} alt="WhoFinance zertifiziert 2023" />
              {/* <img src={whofinance2024} alt="WhoFinance zertifiziert 2024" /> */}
            </div>

            <div className="row">
              {/* <img src={whofinance2023} alt="WhoFinance zertifiziert 2023" /> */}
              <img src={whofinance2022} alt="WhoFinance zertifiziert 2022" />
              <img src={whofinance2021} alt="WhoFinance zertifiziert 2021" />
            </div>
          </ZertifikateGrid>

          <CertificateButtons>
            <a
              className="button flat"
              href="https://www.google.com/search?q=Andreas+Burkhardt+Mellrichstadt"
              target="_blank"
              rel="noreferrer"
            >
              <FaGoogle /> Google Bewertungen
            </a>

            <a
              className="button flat"
              href="https://www.whofinance.de/berater/andreas-burkhardt/"
              target="_blank"
              rel="noreferrer"
            >
              <FaExclamation /> WhoFinance Bewertungen
            </a>
          </CertificateButtons>
        </SectionContent>
      </ZertifikateSection>

      <LeistungenSection id="leistungen">
        <SectionContent>
          <h2>Leistungen</h2>
          <LeistungenGrid>
            <div>
              <LeistungenHeadline>
                <IconGeldanlage />
                <h4>Geldanlage</h4>
              </LeistungenHeadline>

              <ul>
                <li>
                  <FaCheck /> ETF’s
                </li>
                <li>
                  <FaCheck /> Fonds
                </li>
                <li>
                  <FaCheck /> Aktien
                </li>
              </ul>
            </div>

            <div>
              <LeistungenHeadline>
                <IconAltersvorsorge />
                <h4>Altersvorsorge</h4>
              </LeistungenHeadline>

              <ul>
                <li>
                  <FaCheck /> Riester-Rente
                </li>
                <li>
                  <FaCheck /> Fondsgebundene Lebensversicherung
                </li>
                <li>
                  <FaCheck /> deutschlandweit
                </li>
              </ul>
            </div>

            <div>
              <LeistungenHeadline>
                <IconBaufinanzierung />
                <h4>Baufinanzierung</h4>
              </LeistungenHeadline>

              <ul>
                <li>
                  <FaCheck /> Einsatz von Eigenkapital
                </li>
                <li>
                  <FaCheck /> Zins
                </li>
                <li>
                  <FaCheck /> Bausparen
                </li>
              </ul>
            </div>

            <div>
              <LeistungenHeadline>
                <IconImmobilienvermittlung />
                <h4>
                  Immobilien-
                  <br />
                  vermittlung
                </h4>
              </LeistungenHeadline>

              <ul>
                <li>
                  <FaCheck /> als Kapitalanlage
                </li>
                <li>
                  <FaCheck /> als Altersvorsorge
                </li>
                <li>
                  <FaCheck /> deutschlandweit
                </li>
              </ul>
            </div>

            <div>
              <LeistungenHeadline>
                <IconVersicherungen />
                <h4>Versicherungen</h4>
              </LeistungenHeadline>

              <ul>
                <li>
                  <FaCheck /> Private Krankenversicherung
                </li>
                <li>
                  <FaCheck /> Sachversicherungen
                </li>
                <li>
                  <FaCheck /> Berufsunfähigkeitsversicherung
                </li>
              </ul>
            </div>

            <div>
              <Link to="/#kontakt" className="button flat">
                Kontakt aufnehmen <FaArrowRight />
              </Link>
            </div>
          </LeistungenGrid>
        </SectionContent>
      </LeistungenSection>

      <Section>
        <SectionContent className="collapsed">
          <Blockquote
            text="Super verständliche und professionelle Beratung. Es wurde auf den aktuellen Wissensstand eingegangen, sich viel Zeit genommen um alle wichtigen Punkte zu erklären und alle offenen Fragen verständlich beantwortet. Zu allen Produkten wurden transparent die Vor- und Nachteile aufgezeigt. Wirklich empfehlenswert!"
            author="Benjamin"
          />
        </SectionContent>
      </Section>

      <PhilosophieSection>
        <SectionContent>
          <PhilosophieHeader>
            <h3>Philosphie</h3>
            <img src={defino} alt="Defino zertifiziert" />
          </PhilosophieHeader>

          <PhilosophieContent>
            <p>
              Jede Art der Investition hat seine Vorteile, aber auch Nachteile
              bzw. Risiken.
            </p>
            <p>
              Daher möchte ich im ersten Gespräch gerne herausfinden, welche
              Parameter für meine Mandanten wichtig sind.
            </p>
            <ul>
              <li>
                <FaCheck /> Welche Ziele und Wünsche gibt es im Leben
                (kurz-mittel- und langfristig)?
              </li>
              <li>
                <FaCheck /> Welcher Anlegertyp ist die Person?
              </li>
              <li>
                <FaCheck /> Wie wichtig ist Flexibilität, Rendite und
                Sicherheit?
              </li>
              <li>
                <FaCheck /> Und vieles mehr, je nach Lebenslage...
              </li>
            </ul>
            <p>
              Basierend auf den Antworten erstelle ich in Absprache mit meinen
              Mandanten ein umfassendes Konzept mit den passenden Lösungen.
              Hierbei prüfe ich, welche Produkte und Gesellschaften am Markt in
              Frage kommen.
            </p>
            <p>
              Der Mandant kann sich dann aus der Vorauswahl entsprechend die
              Lösung heraussuchen, mit der er sich am wohlsten fühlt.
            </p>
            <p>
              Ziel ist es für mich ein lebenslanger Ansprechpartner im Bereich
              Finanzen zu sein.
            </p>
          </PhilosophieContent>

          <Link to="/#kontakt" className="button accent">
            Kontakt aufnehmen <FaArrowRight />
          </Link>
        </SectionContent>
      </PhilosophieSection>

      <BeratungSection id="beratung">
        <SectionContent>
          <h2>Beratungsansatz</h2>

          <BeratungGrid>
            <div>
              <IconIndividuell />
              <h4>Individuell</h4>
            </div>
            <div>
              <IconLangfristig />
              <h4>Langfristig</h4>
            </div>
            <div>
              <IconUnabhaengig />
              <h4>Unabhängig</h4>
            </div>
            <div>
              <IconVerstaendlich />
              <h4>Verständlich</h4>
            </div>
          </BeratungGrid>

          <Link to="/#kontakt" className="button flat">
            Kontakt aufnehmen <FaArrowRight />
          </Link>
        </SectionContent>
      </BeratungSection>

      <Section>
        <SectionContent>
          <Blockquote
            text="Die Beratung war sehr anschaulich, strukturiert und so verständlich, dass auch ein Laie, ohne jegliche Finanzkenntnisse, alles nachvollziehen kann. [...] Ich fühlte und fühle mich bei Herrn Burkhardt bestens aufgehoben und kann ihn als Berater nur wärmstens weiterempfehlen."
            author="Sebastian B."
          />
        </SectionContent>
      </Section>

      <AboutSection id="ueber-mich">
        <SectionContent>
          <h2>Über Mich</h2>
          <AboutGrid>
            <img src={burki} alt="" />
            <div>
              <h3>Zertifizierter Spezialist für private Finanzanalyse</h3>
              <p>
                Unabhängiger, selbstständiger Makler &mdash; von Herzen. Themen
                wie Finanzen und Immobilien waren für mich bereits privat
                relevant, noch bevor ich mich dazu entschloss, mich einem
                Makler-Pool anzuschliessen, welcher auf Verbraucherschutzbasis
                arbeitet. Dieser Partner an meiner Seite und die
                DIN-Zertifizierung ermöglichen mir Finanzberatung von höchster
                Qualität und Transparenz anzubieten.
              </p>
              <p>
                Nach diversen Weiterbildungen und Zusatzqualifikationen biete
                ich nun meine Erfahrung und mein Wissen in meiner Heimatstadt
                Mellrichstadt als Ansprechpartner im Bereich Versicherungen,
                Finanzen und Baufinanzierung an.
              </p>
              {/* <h3>Zertifikate</h3>
              <ZertifikateGrid>
                <img src={whofinance2021} alt="WhoFinance zertifiziert" />
                <img src={defino} alt="Defino zertifiziert" />
              </ZertifikateGrid> */}
            </div>
          </AboutGrid>
          <h3>Persönlicher Werdegang</h3>
          <p>
            An dieser Stelle möchte ich einfach noch kurz ein wenig über mich
            erzählen. Bis zu meinem Abitur, war ich ein waschechter
            Mellerschter. Ich war v.a. sportlich aktiv als Handballer,
            Fußballer, Volleyballer und im Tennisverein. Doch dann habe ich mir
            vorgenommen, in meinen Zwanzigern zu studieren und ins Ausland zu
            reisen und auch mal dort zu leben. Direkt nach dem Abitur habe ich
            daher einbandhalb Jahre in Dublin gelebt und bei UPS in einem
            Call-Center für die Rechnungsprüfung gearbeitet, um mein
            Englisch-Kenntnisse zu erweitern. Glücklicherweise ist Dublin der
            Heimat-Flughafen von Ryan-Air, was mir ermöglichte in verschiedene
            Städte in Großbritannien und Skandinavien zu reisen.
          </p>
          <p>
            Im Anschluss ging es für mich nach Deggendorf um Medientechnik zu
            studieren. Ich fand es reizvoll einen kreativen
            Ingenieurs-Studiengang zu wählen, da ich mich somit auf kreativer
            Ebene austoben konnte, mich aber auch mit komplexen Sachverhalten
            der höheren Mathematik, Physik, Informatik und Elektrotechnik
            auseinander setzen musste. Hier ist problemlösendes Denken gefragt,
            welches mir heute in meinem Job zu Gute kommt. Zum Ende meines
            Studiums habe ich mich dazu entschieden, nochmal ein Jahr im Ausland
            als Erasmus-Student zu leben. So verbrachte ich nochmal sechs Monate
            in Gandia als Student und arbeitete sechs Monate in Valencia in
            einem Start-Up-Unternehmen.
          </p>
          <p>
            Mit dem Ende meiner akademischen Ausbildung begann dann endlich das
            Arbeitsleben. Basierend auf meinen Erfahrungen der letzten Jahre,
            war es für mich klar, dass ich gerne Menschen mit Rat und Tat zur
            Seite stehe und somit sammelte ich Berufserfahrungen bei UPS als
            Firmenkunden-Betreuer und im Photovoltaik-Bereich. Da ich mich aber
            schon immer gerne privat mit dem Themen Finanzen und Immobilien
            auseinander gesetzt habe, war es für mich reizvoll auch beruflich in
            diesem Bereich tätig zu werden. So kam es, dass ich mich dazu
            entschied als unabhängiger Makler selbstständig zu machen und mich
            einem Makler-Pool anzuschliessen, welcher auf Verbraucherschutzbasis
            arbeitet. Dieser Partner an meiner Seite und die DIN-Zertifizierung
            ermöglichen mir Finanzberatung von höchster Qualität und Transparenz
            anzubieten.
          </p>
          <p>
            Nach jahrelangen Weiterbildungen und Zusatzqualifikationen habe ich
            mich dazu entschieden meine Erfahrung und mein Wissen in meiner
            Heimatstadt Mellrichstadt anzubieten und der Ansprechpartner im
            Bereich Versicherungen, Finanzen und Baufinanzierung zu sein.
          </p>
        </SectionContent>
      </AboutSection>

      <ContactSection id="kontakt">
        <SectionContent>
          <h2>Kontakt</h2>

          <ContactGrid>
            <div>
              <h3>{name}</h3>

              {/* <p>
                <a
                  href="https://wuerzburg.formaxx.de/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={logo} alt="FORMAXX AG" height="24" />
                </a>
              </p> */}

              <p>
                <span>
                  Mobil: <a href={`tel:${phonelink}`}>{phone}</a>
                </span>

                <br />
                <span>
                  E-Mail: <a href={`mailto:${email}`}>{email}</a>
                </span>
              </p>

              <SocialLinksVertical>
                <p>Social Media:</p>
                <ul>
                  <li>
                    <a
                      href="https://www.google.com/search?q=Andreas+Burkhardt+Mellrichstadt"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaGoogle />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.whofinance.de/berater/andreas-burkhardt/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaExclamation />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.xing.com/profile/Andreas_Burkhardt38/cv"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaXing />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/Mellrichstadt"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaFacebookF />
                    </a>
                  </li>
                </ul>
              </SocialLinksVertical>
            </div>

            <div>
              <ContactStandort>
                <h4>Büro Mellrichstadt</h4>
                <p>
                  <span>{street}</span>
                  <br />
                  <span>{zip} </span>
                  <span>{city}</span>
                </p>
              </ContactStandort>

              {/* <ContactStandort>
                <h4>Büro Würzburg</h4>
                <p>
                  <span>Friedrich-Ebert-Ring 1</span>
                  <br />
                  <span>97072 Würzburg</span>
                </p>
              </ContactStandort> */}
            </div>
          </ContactGrid>
        </SectionContent>
      </ContactSection>
    </>
  );
}
