import React from "react";
import styled from "styled-components";
import { FaGoogle, FaExclamation } from "react-icons/fa";
import breakpoints from "../utils/breakpoints";
import { SocialLinksHorizontal } from "./SocialLinks";

const BlockquoteStyles = styled.blockquote`
  --blockquote-spacing: var(--spacing-s);

  ${breakpoints.medium`
    --blockquote-spacing: var(--spacing);
  `};

  margin: 0;

  p {
    font-size: var(--font-size-l);
    font-weight: var(--font-weight-light);
    font-style: italic;
    /* letter-spacing: 1px; */
    margin-bottom: var(--spacing-s);
  }

  hr {
    background-color: var(--color-primary);

    &:first-child {
      margin-top: 0;
      margin-bottom: var(--blockquote-spacing);
    }

    &:last-child {
      margin-top: var(--blockquote-spacing);
      margin-bottom: 0;
    }
  }
`;

const CiteContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing);

  ${breakpoints.medium`
    gap: 0;
  `};

  ${SocialLinksHorizontal} {
    align-self: flex-end;
    gap: var(--spacing-xs);
    flex-direction: column;
    align-items: start;

    ${breakpoints.medium`
    flex-direction: row;
    align-items: center;
    `};

    ${breakpoints.large`
      gap: var(--spacing-s);
    `};

    p {
      font-size: var(--font-size);
      font-style: normal;
      margin-bottom: 0;
    }

    ul {
      flex-direction: column;
      align-items: start;

      ${breakpoints.medium`
      flex-direction: row;
      align-items: center;
    `};

      li a {
        padding-left: 0;

        ${breakpoints.medium`
          padding-left: var(--spacing-s);
        `};
      }
    }
  }
`;

export default function Blockquote({ color = "", text = "", author = "" }) {
  return (
    <BlockquoteStyles className={`${color}`}>
      <hr />
      <p>&bdquo;{text}&ldquo;</p>

      <CiteContainer>
        <p>
          <cite> &mdash; {author}</cite>
        </p>

        <SocialLinksHorizontal>
          <p>Weitere Bewertungen:</p>
          <ul>
            <li>
              <a
                href="https://www.google.com/search?q=Andreas+Burkhardt+Mellrichstadt"
                target="_blank"
                rel="noreferrer"
              >
                <FaGoogle /> Google Bewertungen
              </a>
            </li>
            <li>
              <a
                href="https://www.whofinance.de/berater/andreas-burkhardt/"
                target="_blank"
                rel="noreferrer"
              >
                <FaExclamation /> WhoFinance Bewertungen
              </a>
            </li>
          </ul>
        </SocialLinksHorizontal>
      </CiteContainer>
    </BlockquoteStyles>
  );
}
